/*
//┐
//│  ╔═════════════════════════════╗
//│  ║                             ║
//╠──╢ defined base url			   ║
//│  ║                             ║
//│  ╚═════════════════════════════╝
//┘
*/ 
var root_url = $("#data_url").data("url");

function loading() {
	$(".loader,.loader2").removeClass("active");
	setTimeout(function(){ 
	$(".loader,.loader2").addClass("active");
	}, 1);
	setTimeout(function(){ 
	$(".loader,.loader2").removeClass("active");
	}, 3000);
}


$(document).ready(function(){

	/*
	//┐
	//│  ╔═════════════════════════════╗
	//│  ║                             ║
	//╠──╢ menu off canvas close		   ║
	//│  ║                             ║
	//│  ╚═════════════════════════════╝
	//┘ 
	*/

	$(".button-collapse").sideNav();
	$('.button-collapse').sideNav({
	      menuWidth: 300, // Default is 300
	      edge: 'left', // Choose the horizontal origin
	      closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
	      draggable: false, // Choose whether you can drag to open on touch screens,
	      onOpen: function(el) {$(".offcanvasclose").addClass("active");}, // A function to be called when sideNav is opened
	      onClose: function(el) { $(".offcanvasclose").removeClass("active");}, // A function to be called when sideNav is closed
	    }
	  );
	  

	
	$("html" ).on("click",".offcanvasclose",function(){
		var toclose = $(this).data("close");
		$('#'+toclose).sideNav('hide');
	});
	
	setTimeout(function(){ 
		loading();
		setTimeout(function(){ 
			$(".items").addClass("active");
			$(".intro").removeClass("open");
			$("#main").addClass("introopen");
		}, 1000);
	}, 4000);
	/*$(".intro" ).on("click",function(){
		loading();
		setTimeout(function(){ 
			$(".items").addClass("active");
			$(".intro").removeClass("open");
			$("#main").addClass("introopen");
		}, 1000);
		
	});*/
	
	/*$(".seeallmap" ).on("click",function(){

		$(this).parent().addClass("full");

		
	})*/
	
	
	$(".item,.points .btn-floating, #menu a,.footer li a" ).on("click",function(){
		var id = $(this).data("id");
		loading();
		setTimeout(function(){
			$(".overmap" ).removeClass('pageopen');
			$(".scrolltodown").data("target","#"+id+" .contentpage");
			console.log($(".scrolltodown").data("target"));
			$("#"+id+",#main" ).addClass('pageopen');
			$("body, html").animate({ scrollTop:  $("#content"+id).offset().top }, "slow");
		}, 1000);
	});
	
	$(".overmap .close" ).on("click",function(){
		
		loading();
		setTimeout(function(){ 
			$(".overmap,#main" ).removeClass('pageopen');
			$(".scrolltodown").data("target",".items");
		}, 1000);
	});
	
	$("body, html").on("click",".scrolltodown",function() {
		var target = $(this).data("target");
	  $("body, html").animate({ scrollTop: $(target).offset().top }, "slow");
	  return false;
	});
	
	$(".scrolltotop").on("click",function() {
	  $("body, html").animate({ scrollTop: 0 }, "slow");
	  return false;
	});
	
	$(window).scroll(function() {
	    console.log($('.scroller').offset().top);
	    if( 
	    	$('.scroller').offset().top> 40
	    ){
	    	$('#main').addClass('scrolled');
	    	$(".scrolltodown").removeClass("active")
	    	$(".scrolltotop").addClass("active");
	    	
	    	
	    }else{
	    
	    	$('#main').removeClass('scrolled');
	    	$(".scrolltotop").removeClass("active")
	    	$(".scrolltodown").addClass("active");
	    	//$(".scrolltotopdown i").removeClass("icon-android-arrow-up").addClass("icon-android-arrow-down");
	    	
	    }
	});


});
/*
$(window).load(function(){
	$(".loader,.loader2").removeClass("active");
});*/